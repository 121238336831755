import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { injectIntl, useIntl } from 'react-intl';
import moment from 'moment-timezone';
import ModalDlg from 'components/Basic/ModalDlg';
import ContentHeader from 'containers/Dashboard/ContentHeader';
import OrderFilter from 'components/Dashboard/Orders/OrderFilter';
import OrdersTable from 'components/Dashboard/Orders/OrdersTable';
import OrderDetailModal from 'components/Dashboard/Orders/OrderDetailModal';
import OrderStatusModal from 'components/Dashboard/Orders/OrderStatusModal';
import Loading from 'components/Basic/Loading';
import toast from 'components/Basic/Toast';
import { useLazyGetDistributionOrdersQuery, useOrderProceedMutation } from 'core/order/OrderService';

function OrderAnalysisModal({ intl, visible, orderState, onCloseModal }) {
  const [orderDetail, setOrderDetail] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenSarModal, setIsOpenSarModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [list, setList] = useState({});
  const [searchFilterQuery, setSearchFilterQuery] = useState('');
  const [sort, setSort] = useState('');

  const onSelectOrder = (modalType, v) => {
    setOrderDetail(v);
    setIsOpenModal(modalType === 'detail');
    setIsOpenSarModal(modalType === 'sar');
  };

  const [getOrderList] = useLazyGetDistributionOrdersQuery();
  const [orderProceed] = useOrderProceedMutation();

  const handleChangePage = async (o, l, query, s) => {
    setOffset(o);
    setLimit(l);
    setIsLoading(true);
    const stateQuery = `&filter=state in ${orderState}`;
    const timeQuery = `&filter=updatedAt gte ${moment().subtract(1, 'hours').tz('America/Guatemala')}`;

    try {
      const res = await getOrderList({
        offset: o,
        limit: l,
        or: false,
        filter: (query || searchFilterQuery || '') + (orderState ? stateQuery : '') + timeQuery,
        sort: s || sort,
        distribution_received: orderState === 'RV',
      }).unwrap();
      setList(res);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (visible && orderState) {
      handleChangePage(offset, limit);
    }
    if (!visible) {
      setList({});
    }
  }, [orderState, visible]);

  const handleSort = value => {
    setSort(value);
  };

  const handleSearch = e => {
    setSearchText(e.target.value);
  };

  const handleKeyDownSearch = e => {
    let tempSearchFilterQuery = '';
    if (e.keyCode === 13) {
      if (searchText) {
        if (!isNaN(searchText, 10) && searchText < 100000000) {
          tempSearchFilterQuery += `&filter=or.id eq ${searchText}`;
        }
        tempSearchFilterQuery += `&filter=ex.fullName iLike %25${searchText}%25&filter=ex.phone iLike %25${searchText}%25&filter=or.order iLike %25${searchText}%25&filter=or.deliveryAddress iLike %25${searchText}%25`;
      } else {
        tempSearchFilterQuery = '';
      }
      setSearchFilterQuery(tempSearchFilterQuery);
      setTimeout(() => {
        handleChangePage(0, 10, tempSearchFilterQuery, sort);
      }, 1000);
    }
  };

  const handleApplyFilter = () => {
    handleChangePage(0, 10, searchFilterQuery, sort);
  };

  const handleSendToSar = async id => {
    setIsOpenSarModal(false);
    try {
      await orderProceed({ id }).unwrap();
      toast.success({
        title: intl.formatMessage({ id: 'Order is sent to SAR successfully' }),
      });
    } catch (e) {
      toast.error({
        title: intl.formatMessage({ id: 'Sending to SAR is failure' }),
      });
    }
  };

  return (
    <ModalDlg
      className="order_analysis_modal"
      visible={visible}
      width={1142}
      top={30}
      handleCancel={() => {
        onCloseModal();
      }}
    >
      <Loading visible={isLoading} />
      <ContentHeader
        type="order_analisys"
        searchText={searchText}
        onSearch={handleSearch}
        onKeyDownSearch={handleKeyDownSearch}
      />
      <div className="orders_filter_wrapper">
        <OrderFilter onSort={handleSort} onApplyFilter={handleApplyFilter} mode="modal" />
        <OrdersTable
          onSelectOrder={onSelectOrder}
          orderData={list.rows || []}
          onChangePage={handleChangePage}
          total={list.totalCount || 0}
          mode="modal"
        />
        <OrderDetailModal
          orderDetail={orderDetail}
          isOpenModal={isOpenModal}
          onCloseModal={() => setIsOpenModal(false)}
        />
        <OrderStatusModal
          orderDetail={orderDetail}
          isOpenModal={isOpenSarModal}
          onCloseModal={() => setIsOpenSarModal(false)}
          onSendSAR={handleSendToSar}
        />
      </div>
    </ModalDlg>
  );
}

OrderAnalysisModal.defaultProps = {
  visible: false,
  list: {},
  orderState: '',
};

export default compose(injectIntl)(OrderAnalysisModal);
